@use "colors.module" as *;
@use "variables.module" as *;
@use "functions.module" as *;

.odeza-system-h5 {
    font-size: $fontSizeH5;
    font-weight: $fontWeightMedium;
    line-height: $lineHeightH5;
    color: $ensembleNavy;
}

.odeza-system-h6 {
    font-size: $fontSizeH6;
    font-weight: $fontWeightRegular;
    line-height: $lineHeightH5;
    color: $ensembleNavy;
}
