@use "colors.module" as *;
@use "variables.module" as *;
@use "functions.module" as *;

.odeza-button--text {
    color: $ensembleNavy;
    text-transform: none;
    font-size: rem(13px);
    line-height: 1.7;
    padding: rem(5px) rem(11px);
    border-radius: rem(2px);

    &:hover {
        background-color: $odezaIceBlue;
    }

    &.Mui-focusVisible {
        background-color: $odysseyGreyPressed;
    }

    &.Mui-disabled {
        color: $odysseyGreyDisabled;
    }
}

.button-unstyled {
    border: none;
    background: transparent;
    font-weight: inherit;
    color: inherit;
    padding: 0;
    line-height: 1;

    &:focus {
        box-shadow: none;
    }

    &:focus-visible {
        box-shadow: 0 0 0 0.1rem rgb(6 29 83 / 50%);
        outline: 0;
    }
}
