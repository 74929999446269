@use "functions.module" as *;

$roboto: "Roboto", Arial, sans-serif, FontAwesome !important;

$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

$fontSizeH5: rem(18px);
$fontSizeH6: rem(15px);

$lineHeight: 1.15;
$lineHeightH3: 1.333;
$lineHeightH4: 1.3;
$lineHeightH5: 1.333;
$lineHeightH6: 1.333;

$smBreakpoint: em(600px);
$smMedBreakpoint: em(768px);
$medBreakpoint: em(992px);
