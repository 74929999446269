@use "../../styles/functions.module" as *;
@use "../../styles/colors.module" as *;
@use "../../styles/variables.module" as *;

.application-container {
    flex: 1 1 auto;
    padding: 0;
    overflow: auto;
}

.application-header {
    background-color: $lightGrey;
}

#dashboard-header {
    background-color: $white;
    padding-top: em(8px);
}

.application-body--standard-width {
    padding: 0 rem(112px);
}

.application-body--full-width {
    padding: 0;
}

.application-body--full-height {
    flex: 1 1 auto;
}

.application-title {
    font-weight: bold;
    font-size: rem(18px);

    > .title-descriptor {
        margin: 0 em(12px, 14px);
        font-weight: normal;
        font-size: rem(14px);
        color: $lightFontColor;
    }
}

.application-title--no-sidebar {
    padding: em(42px, 18px) 0 em(12px, 18px) em(112px, 18px);
}

.application-title--with-sidebar {
    padding: 0;
}

.application-error {
    text-align: center;
    margin-top: em(32px);
    font-weight: bold;
    color: $odezaRed;
}

.application-modal-body {
    .title {
        font-size: rem(16px);
        font-weight: bold;
    }

    .descriptor {
        font-size: rem(14px);
        color: $lightFontColor;
    }
}

.application-modal-footer {
    .buttons {
        .secondary {
            background-color: transparent;
            color: $odezaDarkBlue;
            text-decoration: underline;
            text-decoration-thickness: rem(1px);
            font-size: rem(16px);
            font-weight: lighter;
            padding-right: em(16px);
            border: none;

            &:hover,
            &:focus {
                text-decoration: underline;
                background-color: transparent !important;
                color: $odezaDarkBlue !important;
            }
        }
    }
}
