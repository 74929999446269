$defaultFontColor: rgb(38, 50, 56); //#263238

$lightFontColor: rgb(127, 146, 157); //#7F929D
$lightGrey: rgb(241, 244, 246); //#F1F4F6
$disabledFontColor: rgb(164, 169, 171); //#A4A9AB
$errorRed: rgb(219, 43, 43); //#DB2B2B

$odezaDarkGrey: rgb(120, 144, 156); //#78909c
$odezaMedDarkGrey: rgb(144, 164, 174); //#90a4ae
$odezaGrey: rgb(222, 226, 225); //#dee2e1
$odezaMedLightGrey: rgb(231, 238, 240); //#e7eef0
$odezaLightGrey: rgb(245, 247, 246); //#f5f7f6
$odezaChartGrey: rgb(191, 191, 191); //#bfbfbf

$odezaDarkTeal: rgb(112, 169, 175); //#70a9af
$odezaLightTeal: rgb(235, 241, 241); //#ebf1f1
$odezaTeal: rgb(184, 232, 237); //#b8e8ed

$odezaDarkBlue: rgb(39, 169, 248); //#27a9f8
$odezaDarkBlueHover: rgb(73, 182, 249); //#49b6f9
$odezaBlue: rgb(180, 220, 250); //#b4dcfa
$odezaLightBlue: rgb(195, 227, 247); //#c3e3f7
$odezaLightestBlue: rgb(221, 239, 249); //#ddeff9
$odezaIceBlue: rgb(235, 248, 249); // #ebf8f9
$odezaOrange: rgb(255, 154, 48); //#ff9a30
$odezaGreen: rgb(155, 187, 89); //#9bbb59
$odezaRed: rgb(240, 80, 80); //#f05050
$odezaYellow: rgb(255, 192, 0); //#ffc000
$odezaDarkNavy: rgb(66, 91, 118); //#425b76

$white: rgb(255, 255, 255); //#ffffff
$odysseyLightGrey: rgb(241, 244, 246); //#F1F4F6
$odysseyLightGreyHover: rgb(248, 249, 250); //#F8F9FA;
$odysseyLightestGrey: rgb(239, 239, 239); //#EFEFEF
$odysseyGrey: rgb(115, 115, 115); //#737373
$odysseyGreyPressed: rgb(212, 223, 224); //#D4DFE0
$odysseyGreyDisabled: rgb(130, 142, 169); //#828EA9
$odysseyDarkGrey: rgb(99, 117, 128); //#637580
$odysseyLightBlue: rgb(234, 248, 249); //#EAF8F9

$ensembleNavy: rgb(6, 29, 83); //#061D53
$ensembleOffNavy: rgb(45, 64, 109); //#2D406D
$ensembleDarkGray: rgb(127, 146, 157); //#7F929D
$ensembleMediumGray: rgb(199, 209, 214); //#C7D1D6

// These may be somewhat temporary
$chatGray: rgb(145, 143, 143); //#918f8f
$chatGrayHover: rgb(76, 76, 76); //#4c4c4c
$chatDarkGrayHover: rgb(56, 74, 117); //#384a75

:export {
    defaultFontColor: $defaultFontColor;

    odezaDarkGrey: $odezaDarkGrey;
    odezaMedDarkGrey: $odezaMedDarkGrey;
    odezaGrey: $odezaGrey;
    odezaMedLightGrey: $odezaMedLightGrey;
    odezaLightGrey: $odezaLightGrey;
    odezaChartGrey: $odezaChartGrey;

    odezaDarkTeal: $odezaDarkTeal;
    odezaLightTeal: $odezaLightTeal;
    odezaTeal: $odezaTeal;

    odezaDarkBlue: $odezaDarkBlue;
    odezaDarkBlueHover: $odezaDarkBlueHover;
    odezaBlue: $odezaBlue;
    odezaLightBlue: $odezaLightBlue;
    odezaLightestBlue: $odezaLightestBlue;
    odezaIceBlue: $odezaIceBlue;
    odezaOrange: $odezaOrange;
    odezaGreen: $odezaGreen;
    odezaRed: $odezaRed;
    odezaYellow: $odezaYellow;
    odezaDarkNavy: $odezaDarkNavy;

    ensembleNavy: $ensembleNavy;
    ensembleOffNavy: $ensembleOffNavy;
    ensembleDarkGray: $ensembleDarkGray;
    ensembleMediumGray: $ensembleMediumGray;

    odysseyLightGrey: $odysseyLightGrey;
    odysseyLightestGrey: $odysseyLightestGrey;
    odysseyDarkGrey: $odysseyDarkGrey;
    odysseyLightBlue: $odysseyLightBlue;

    chatGrayHover: $chatGray;
    chatGrayHover: $chatGrayHover;
    chatDarkGrayHover: $chatDarkGrayHover;
}
