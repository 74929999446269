@use "colors.module" as *;
@use "variables.module" as *;
@use "functions.module" as *;

.modal {
    font-family: $roboto;

    .modal-dialog.modal-sm {
        max-width: rem(320px);
        margin: rem(28px) auto;
    }

    &.modal--switcher .modal-body {
        padding: em(24px) !important;
        font-size: rem(16px);
    }

    &.modal--switcher-small .modal-footer {
        margin-top: 0;
    }

    .modal-header {
        padding: em(24px);
        font-size: rem(16px);
        align-items: center;
        border-bottom: rem(1px) solid $odysseyLightestGrey;
    }

    .modal__close {
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;
        line-height: $lineHeight;
        background-color: initial;
    }

    .modal__x-icon {
        color: $ensembleNavy;
        font-size: rem(16px);
        width: rem(12px);
        height: auto;
    }

    .modal__close:hover .modal__x-icon {
        color: $ensembleOffNavy;
    }

    .modal-title {
        font-size: rem(18px);
        line-height: $lineHeight;
        font-weight: $fontWeightBold;
        color: $ensembleNavy;
    }

    .modal-content {
        border-radius: rem(2px);
        border: none;
    }

    .modal-footer {
        border-top: none;
        padding: rem(8px) rem(24px) rem(24px) rem(24px);

        .btn:first-child {
            margin: 0;
        }

        .btn:last-child {
            margin: 0 0 0 rem(24px);
        }
    }

    .custom-modal {
        &.header {
            padding: em(24px) em(36px) em(24px);
            border-bottom: rem(1px) solid $odezaGrey;
            align-items: flex-start;
        }

        &.title-wrapper {
            flex-direction: column;

            :first-child {
                margin-bottom: rem(5px);
            }
        }

        &.title {
            width: 100%;
            font-weight: $fontWeightBold;
            line-height: $lineHeight;
            font-size: rem(18px);
        }

        &.subtitle {
            width: 100%;
            font-weight: 300;
            font-size: rem(14px);
            line-height: $lineHeight;
            color: #061d53;
        }

        &.body {
            padding-left: em(32px);
            padding-right: em(32px);
        }

        &.footer {
            padding-left: em(32px);
            padding-right: em(32px);
            border-top: 0;
        }
    }
}

.modal-backdrop {
    background-color: $ensembleDarkGray !important;
}

.react-confirm-alert-overlay {
    background: transparent !important;
}
