@use "colors.module" as *;

body {
    font-family: "Open Sans", Arial, sans-serif, FontAwesome !important;
    -webkit-font-smoothing: antialiased !important;
    font-size: 12px;
    overflow-x: hidden;
    background-color: $odezaLightGrey;
    color: $defaultFontColor;
}

#root {
    overflow-x: hidden;
}

textarea {
    resize: vertical; /* user can resize vertically, but width is fixed */
}

.odeza-content-card {
    background-color: white;
    border-radius: 8px;

    svg.loading {
        font-size: 100px;
        opacity: 0.5;
        margin-top: 100px;
    }

    div#noTasks {
        font-size: 24px;
        text-align: center;
        padding-top: 50px;
        color: $odezaMedDarkGrey;
    }
}

.pg-records {
    border-radius: 4px;
}

.odeza-font-15 {
    font-size: 15px;
}

.odeza-font-16 {
    font-size: 16px;
}

.odeza-font-18 {
    font-size: 18px;
}

.odeza-font-20 {
    font-size: 20px;
}

.odeza-content-padding-top {
    padding-top: 20px;
}

.odeza-content-padding-bottom {
    padding-bottom: 20px;
}

.odeza-row-item {
    margin-top: 20px;
    margin-bottom: 10px;
}

.odeza-hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.odeza-margin-top {
    margin-top: 10px;
}

.odeza-text-align-right {
    text-align: right;
}

.odeza-error {
    color: $odezaRed;
    margin-top: 5px;

    &::first-letter {
        text-transform: capitalize;
    }
}

.odeza-indent-left {
    padding-left: 20px;
}

.odeza-small-top {
    padding-top: 5px;
}

.modal-footer {
    text-align: left;
}

.odeza-menulist select {
    height: 28px;
}

.odeza-action-button {
    padding: 0px 5px 0px 5px;
    color: rgb(165, 165, 165); //#a5a5a5
    &:hover,
    &:focus,
    &:active {
        color: rgb(70, 70, 70); //#464646
    }
}

.btn {
    &-sm {
        font-size: 0.75rem;
        padding: 0.3rem 0.6rem;
    }

    box-shadow: none;

    &:active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled) {
        box-shadow: none;
    }

    &:focus,
    &:active:focus {
        outline: none;
    }
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}

.btn-info {
    background-color: $odezaDarkBlue;
    border-color: $odezaDarkBlue;
    color: white;
    font-weight: 700;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: $odezaDarkBlueHover;
        border-color: $odezaDarkBlueHover;
    }

    &:disabled:focus,
    &.disabled:focus,
    &:disabled:hover,
    &.disabled:hover,
    &:disabled:active,
    &.disabled:active,
    &:disabled:active:focus,
    &.disabled:active:focus {
        background-color: #5bc0de;
        border-color: #46b8da;
        box-shadow: none;
        cursor: not-allowed;
    }
}

.btn-primary,
.btn-primary[disabled] {
    background-color: white;
    border-color: $odezaDarkBlue;
    color: $odezaDarkBlue;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: $odezaBlue;
        border-color: $odezaBlue;
        color: $odezaDarkBlue;
    }
}

.btn-outline-primary,
.btn-outline-primary[disabled] {
    background-color: white;
    border-color: $odezaDarkBlueHover;
    color: $odezaDarkBlueHover;

    &:hover {
        background-color: $odezaDarkBlueHover;
        border-color: $odezaDarkBlueHover;
        color: white;
    }

    &:active {
        background-color: $odezaDarkBlue;
        border-color: $odezaDarkBlue;
        color: white;
    }
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: $odezaDarkBlue;
    border-color: $odezaDarkBlue;
    color: white;
}

.btn-primary,
.btn-primary[disabled] {
    background-color: $odezaDarkBlue;
    border-color: $odezaDarkBlue;
    color: white;

    &:hover {
        background-color: $odezaDarkBlueHover;
        border-color: $odezaDarkBlueHover;
        color: white;
    }

    &:active {
        background-color: $odezaDarkBlue;
        border-color: $odezaDarkBlue;
        color: white;
    }
}

.btn-primary:not(:disabled):not(.disabled):active {
    background-color: $odezaDarkBlue;
    border-color: $odezaDarkBlue;
    color: white;
}

.show > .btn-primary.dropdown-toggle {
    background-color: $odezaDarkBlue;
    border-color: $odezaDarkBlue;
    color: white;

    &:focus {
        box-shadow: none;
    }
}

div.dropdown {
    &.reportDateRangeBtn,
    &.reportDateRangeBtn.show {
        > button {
            background-color: white;
            border-color: $odezaDarkGrey;
            color: $odezaMedDarkGrey;
            font-weight: 700;
            border-radius: 8px;
            border: none;

            &:hover,
            &:focus,
            &:active,
            &:active:focus {
                border-color: $odezaDarkGrey;
                color: $odezaDarkGrey;
                background-color: $odezaLightGrey;
                box-shadow: none;
            }
        }
    }

    &.reportDrilldownBtn,
    &.reportDrilldownBtn.show {
        > button {
            border-radius: 4px;
            border: none;
            color: $odezaDarkBlue;
            background-color: $odezaLightestBlue;

            &:hover,
            &:focus,
            &:active,
            &:active:focus {
                border-color: $odezaDarkGrey;
                color: $odezaDarkGrey;
                background-color: $odezaLightGrey;
                box-shadow: none;
            }
        }
    }
}

.btn-default,
.btn-group.open > .dropdown-toggle.btn-default {
    background-color: white;
    border-color: $odezaDarkGrey;
    color: $odezaMedDarkGrey;
    font-weight: 700;

    &.odeza-drilldown-datepicker-button {
        border: none;
        padding-bottom: 12px;
    }

    &.odeza-back-to-dashboard {
        padding: 2px 10px;
        margin: 0 -5px 5px 15px;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        border-color: $odezaDarkGrey;
        color: $odezaDarkGrey;
        background-color: $odezaLightGrey;
    }
}

#taskViewFilter {
    min-width: 125px;
    display: inline-block;

    > button,
    > button:hover,
    > button:focus,
    > button:active,
    > button:active:focus {
        color: $odezaDarkBlue;
        background-color: $odezaLightestBlue;
        border-color: $odezaLightestBlue;
        font-weight: 700;
    }
}

.usersettings > .dropdown-toggle,
.show > .usersettings > .dropdown-toggle {
    background-color: white;
    color: $odezaDarkTeal;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active {
        color: $odezaDarkGrey;
        background-color: white;
        border: 1px solid transparent;
        box-shadow: none;
    }

    .fa {
        font-size: 24px;
    }
}

.dropdown-menu {
    box-shadow: none;
    padding: 5px 0;

    > a.dropdown-item {
        color: $defaultFontColor;
        font-size: 0.875rem;
        padding: 3px 20px;

        &:focus {
            outline: none;
        }

        &.disabled {
            pointer-events: all;
            cursor: not-allowed;
        }

        &:active {
            background-color: $odezaLightGrey;
        }
    }
}

.productswitcherheading {
    color: $odezaDarkTeal;
    font-size: 19px;
    padding: 6px 12px;
}

.form-control,
.form-control:focus,
.input-group .form-control {
    color: $defaultFontColor;
    border: 1px solid $odezaGrey;
    box-shadow: none;
}

.odeza-searchbox .input-group-addon {
    border: 1px solid $odezaGrey;
    border-right: none;
    color: $odezaMedDarkGrey;
    background-color: white;
}

.odeza-dashboard {
    overflow-y: auto;
}

/*
.odeza-content-border {
  border-top: 2px solid $odezaMedLightGrey;
}
*/

.odeza-web-message-orgname {
    font-weight: 700;
    margin-bottom: 10px;
}

/*IE11 clear button in text box*/
input[type="text"]::-ms-clear {
    display: none;
}

.ct-slice-donut {
    fill: none;
}

.ct-square {
    &:before {
        float: left;
        content: "";
        padding-bottom: 100%;
    }

    > svg {
        position: absolute;
        left: 0;
        top: -30%;
    }
}

.tooltip {
    .tooltip-inner {
        color: white;
        background-color: $odezaDarkNavy;
        border-radius: 2px;
    }

    &.top .tooltip-arrow {
        border-top-color: $odezaDarkNavy;
    }

    &.bottom .tooltip-arrow {
        border-bottom-color: $odezaDarkNavy;
    }
}

.nav-tabs {
    &#patientFilterTabs {
        .nav-item {
            .nav-link {
                &.active,
                &:hover.active,
                &:focus.active {
                    color: $odezaDarkTeal;
                    font-weight: 700;
                    background-color: $odezaLightGrey;
                    border-top: 2px solid $odezaDarkTeal;
                }

                color: $defaultFontColor;
                margin-right: 0px;
                border: 1px solid $odezaGrey;
                border-bottom: none;
                border-radius: 2px 2px 0 0;

                &:hover,
                &:focus {
                    border: 1px solid $odezaGrey;
                    border-bottom: none;
                    outline: none;
                }
            }
        }
    }

    &#associatedContactsTabs {
        overflow-x: scroll;
        flex-drection: row;
        flex-wrap: nowrap;
        padding-top: 10px;
        padding-bottom: 10px;
        border-style: none;

        .nav-item {
            .nav-link {
                &.active {
                    border-bottom-color: $odezaDarkBlue;
                    border-bottom-style: solid;
                    border-bottom-width: 4px;
                    color: black;

                    .associated-contact-name {
                        font-size: 16px;
                    }
                }

                .associated-contact-name {
                    font-weight: bold;
                    font-size: 14px;
                }

                .associated-contact-title {
                    font-size: 12px;
                }

                text-align: left;
                padding-left: 0px;
                margin-right: 20px;
                backgroundcolor: white;
                color: $odezaChartGrey;
                border-style: none;
                word-break: break-word;
            }
        }
    }

    &#historyTabs {
        .nav-item {
            .nav-link {
                &.active,
                &:hover.active,
                &:focus.active {
                    background-color: $odezaDarkBlue;
                    font-weight: bold;
                    color: white;

                    .timeRange {
                        color: white;
                    }
                }

                .timeRange {
                    font-size: 12px;
                    color: $chatGray;
                }

                padding-top: 10px;
                padding-bottom: 10px;
                color: $odysseyGrey;
                font-size: 14px;
                border-style: none;
                text-align: center;
            }
        }
    }
}

.ReactTable {
    border: none !important;

    .rt-thead {
        .dashboardHeader {
            color: $odezaMedDarkGrey;
            text-align: right;
            font-weight: 600;
        }

        .rt-resizable-header-content {
            color: $odezaMedDarkGrey;
        }

        .rt-tr {
            text-align: left !important;
        }

        .rt-th,
        .rt-td {
            border-right: none !important;
        }

        &.-header {
            box-shadow: none !important;
        }
    }

    .rt-tbody {
        .rt-tr-group {
            border-top: solid 1px rgba(0, 0, 0, 0.05);
            border-bottom: none !important;
        }

        .rt-td {
            border-right: none !important;
        }
    }

    .-pagination {
        box-shadow: none !important;

        .-btn {
            color: white !important;
            background: $odezaDarkBlue !important;
            font-weight: 600;

            &[disabled] {
                opacity: 0.3 !important;
            }

            &:not([disabled]):hover {
                background: $odezaDarkBlueHover !important;
            }
        }
    }

    .rt-tfoot {
        box-shadow: none !important;

        .rt-tr {
            border-top: solid 1px rgba(0, 0, 0, 0.05);
        }

        .rt-td {
            border-right: none !important;
        }
    }
}

.odeza-settings-card {
    padding: 15px;
}

.odeza-settings-icon {
    color: $defaultFontColor;
    font-size: 14px;
}

.patient-data {
    padding-top: 10px;
    border: solid $odezaLightGrey;
    border-width: 0px;
    border-bottom-width: 3px;
}
