@use "colors.module" as *;
@use "variables.module" as *;
@use "functions.module" as *;

.odeza-system-table {
    .MuiTableCell-root {
        font-size: rem(14px);
        line-height: $lineHeight;
        color: $ensembleNavy;
        padding: rem(11px) rem(24px) rem(11px) rem(8px);
        border-bottom: 1px solid $odysseyLightGrey;
        white-space: nowrap;
    }

    .odeza-table-cell-red {
        color: $errorRed;
    }

    .MuiTableCell-head {
        font-weight: $fontWeightMedium;
        text-transform: uppercase;
        background-color: $odysseyLightGrey;
        border-bottom: 1px solid $ensembleMediumGray;
        vertical-align: bottom;

        &.odeza-border-left {
            border-left: 1px solid $ensembleMediumGray;
        }
    }

    .MuiTableCell-body {
        font-weight: $fontWeightLight;
        vertical-align: top;
    }
}

.odeza-system-table-wrap {
    overflow-x: auto;
}

.odeza-system-table-wrap--empty {
    min-height: rem(230px);
}

.odeza-system-table--loading {
    opacity: 0.5;
}

.odeza-system-data-header {
    font-weight: $fontWeightMedium;
}
